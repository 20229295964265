import { BrowserRouter, Route, Switch } from "react-router-dom"
import FloatingWhatsApp from 'react-floating-whatsapp'
import Header from "./components/Header"
import Footer from "./components/Footer"
import Home from "./pages/home"
import Blog from "./pages/blog"
import AboutUs from "./pages/aboutus"
import ContactUs from "./pages/contactus"
import BlogDetail from "./pages/blogdetail"
import SEO from "./pages/service/seo"
import WebDesign from "./pages/service/web-design"
import DigitalMarketing from "./pages/service/digital-marketing"
import Error from "./pages/error"
import avatar from './logo.svg'
import "./index.css";

function App() {
  return (
    <BrowserRouter>
      <Header />
      <Switch>
        <Route path="/" exact>
          <Home />
        </Route>
        <Route path="/service/seo" children={< SEO/>}></Route>
        <Route path="/service/web-design" children={< WebDesign/>}></Route>
        <Route path="/service/digital-marketing" children={< DigitalMarketing/>}></Route>
        <Route path="/blog/:category/:slug" children={<BlogDetail />}></Route>
        <Route path="/blog/:category">
          <Blog />
        </Route>
        <Route path="/blog/">
          <Blog />
        </Route>
        <Route path="/about-us/">
          <AboutUs />
        </Route>
        <Route path="/contact-us/">
          <ContactUs />
        </Route>
        <Route path="*">
          <Error />
        </Route>
      </Switch>
      <Footer />
      <FloatingWhatsApp
        className="z-20"
        phoneNumber='123456789'
        accountName='WebTechArea 網頁製作'
        allowClickAway
        avatar={avatar}
        notification
        notificationDelay={30000}
        chatMessage={`您好！很高興能夠為您服務，請問需要甚麼服務？`}
      />
    </BrowserRouter>
  )
}

export default App
