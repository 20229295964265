import React, {useState} from 'react'
import DocumentMeta from 'react-document-meta';
import {
    ChatIcon,
  } from '@heroicons/react/outline'
import ServiceForm from "../components/ServiceForm";

export default function aboutUs() {
    const meta = {
        title: '關於我們 - WebTechArea',
        description: 'Webtecharea.com 是一家專業的網頁開發公司，我們提供全面的網路解決方案，以滿足客戶的需求。我們的團隊擁有豐富的網頁開發經驗和專業知識，能夠為客戶提供具有創新性和高度可靠性的網站和應用程式。我們致力於通過使用最新技術和最佳實踐來實現客戶的目標，並與客戶建立長期的合作關係，為客戶的業務發展提供持續的支持和服務。如果您正在尋找可靠的網頁開發夥伴，Webtecharea.com 是您的最佳選擇。',
        canonical: 'https://webtecharea.com/about-us',
        meta: {
            charset: 'utf-8',
            name: {
            keywords: ''
            }
        }
    };


  return (
    <DocumentMeta {...meta}>
    <div>
      <section className="relative h-[calc(60vh-68px)] sm:h-[calc(80vh-68px)] bg-about-us bg-bottom bg-cover py-[80px] flex items-center before:absolute before:left-1/2 before:-translate-x-1/2 before:bottom-[-65px] before:scale-[.6] ">
      <div className="w-full px-[15px] mx-auto sm:max-w-[540px] md:max-w-[720px] lg:max-w-[1000px]">
        <h3 className="text-2xl md:text-4xl font-bold leading-[3rem] text-[#4c4c4c] ">連接您的網路未來</h3>
        <h5 className="text-[1.1rem] text-[#4c4c4c] tracking-[1.4px] leading-[1.5rem] mb-[1.5rem]">讓您的網路存在更有力量</h5>
        <div className="mt-[50px] text-center md:text-right">
                  <a className="text-[16px] bg-[#000] rounded-[50px] px-[20px] py-[12px] inline-block hover:scale-110 group" href="#">
                    <div className="flex flex-nowrap ">
                      <div className="mr-[10px]">
                        <ChatIcon className="text-blue-300 w-6" />
                      </div>
                      <div className="text-[16px] text-[#fff] group-hover:text-[#fff]">立即聯絡</div>

                    </div>
                  </a>
                </div>
      </div>
    </section>
    <section className="pt-6">
        <div className="w-full px-[15px] mx-auto sm:max-w-[540px] md:max-w-[720px] lg:max-w-[1000px]">
            <h2 className="text-[2rem] font-bold leading-[2.5rem] tracking-[1px] text-center text-[#4c4c4c]">關於我們</h2>
            <p className="my-[20px] tracking-[1px] text-[1rem] leading-[1.7]">
            Webtecharea.com 是一家專業的網頁開發公司，我們提供全面的網路解決方案，以滿足客戶的需求。我們的團隊擁有豐富的網頁開發經驗和專業知識，能夠為客戶提供具有創新性和高度可靠性的網站和應用程式
            </p>
            <p className="my-[20px] tracking-[1px] text-[1rem] leading-[1.7]">我們致力於通過使用最新技術和最佳實踐來實現客戶的目標，並與客戶建立長期的合作關係，為客戶的業務發展提供持續的支持和服務。如果您正在尋找可靠的網頁開發夥伴，Webtecharea.com 是您的最佳選擇。</p>
        </div>
    </section>
    <ServiceForm/>
    </div>
    </DocumentMeta>
  )
}