import DocumentMeta from 'react-document-meta';
import ServiceForm from "../../components/ServiceForm";

export default function WebDesign() {
  const meta = {
    title: '網頁設計|製定合適的網頁設計方案 - WebTechArea',
    description: '我們是一家專業的網頁製作公司，致力於為客戶提供高品質的網頁開發服務。我們的團隊擅長使用最新的網頁設計技術，以及最佳的使用者體驗設計，創造專業、易於使用的網站，為您的業務打造一個強有力的線上品牌形象。',
    canonical: 'https://webtecharea.com/service/web-design',
    meta: {
      charset: 'utf-8',
      name: {
        keywords: ''
      }
    }
  };
  return (
    <DocumentMeta {...meta}>
    <div>
      <div className={` h-[calc(40vh-68px)] bg-center bg-no-repeat bg-cover bg-web-design bg-scroll pb-[10%] pt-[15%] w-full overflow-hidden`}>

      </div>

      <div className="pb-6 md:pb-64 pt-[5%] w-full bg-[#f0f6ff]">
        <div className="">
          <div className="">
            <div className="text-center">
              <h1 className="text-2xl sm:text-4xl text-[#0079bf] font-bold ">網頁設計價錢<span className="text-[#000]">收費標準</span>
              </h1>
            </div>
          </div>
        </div>
      </div>
      <div className="pb-[15%] w-full bg-[#004166] pt-[15%] md:pt-0">
        <div className="max-w-[1220px] w-full flex text-center items-center mx-auto px-[30px]">
          <div className="md:-mt-[15%]">
            <div className="flex flex-col md:flex-row">
              <div className="flex flex-col rounded-[20px] self-start bg-[#fff] shadow-[0_0_40px_0_rgba(0,65,102,0.43)] mb-[80px] md:mb-0">
                <div className="px-[50px] md:px-[30px] lg:px-[80px] py-[50px] pb-[20px] rounded-t-[20px] text-center bg-[#f0f6ff]">
                  <div className="text-[20px] text-[#004166] mb-[30px] font-bold">網頁設計特選套餐</div>
                  <div className="text-[20px] text-[#004166] mb-[30px]">網頁設計套餐<br/>費用低至</div>
                  <div className="text-[60px] font-bold text-[#004166] border-b-[1px] border-[#004166] whitespace-nowrap"><span className="text-[18px] inline-block align-super">$</span>2688</div>
                </div>
                <div className="px-[50px] md:px-[30px] lg:px-[80px] py-[50px] pt-[15px]">
                  <ul className="mb-[40px] list-outside list-disc pl-[15px]">
                    <li className="pb-[5px]">一至五頁專業網站版面</li>
                    <li className="pb-[5px]">電腦版 及 手機版設計</li>
                    <li className="pb-[5px]">首頁動態横額</li>
                    <li className="pb-[5px]">網頁查詢表格</li>
                    <li className="pb-[5px]">返回頁首按鍵</li>
                    <li className="pb-[5px]">網頁Icon</li>
                    <li className="pb-[5px]">網頁Favicon</li>
                    <li className="pb-[5px]">相片畫集</li>
                    <li className="pb-[5px]">動態相簿</li>
                    <li className="pb-[5px]">社交平台連結 (Facebook / Instagram / Twitter)</li>
                    <li className="">一種語言</li>
                  </ul>
                  <a href="#" className="text-[12px] block text-center bg-[#b4e1fa] py-[12px] rounded-[30px] shadow-[0_20px_20px_0_rgba(0,0,0,0.1)] hover:bg-[#0079bf] hover:text-[#fff] hover:-translate-y-[5px] transition-transform duration-300">索取報價單 </a>
                </div>
              </div>
              <div className="relative flex flex-col rounded-[20px] self-start bg-[#fff] shadow-[0_0_40px_0_rgba(0,65,102,0.43)] md:ml-[30px] mb-[50px] md:mb-0">
                <div className="absolute left-1/2 -translate-x-1/2 font-bold animate-[jump_1s_ease-in-out_infinite_0s_alternate]">
                    <div className="text-[20px] text-[#ee2312]">熱門之選</div>
                    <div className="bg-[#ee2312] h-[40px] w-[1px] mx-auto"></div>
                </div>
                <div className="px-[50px] md:px-[30px] lg:px-[80px] py-[50px] pb-[20px] rounded-t-[20px] text-center bg-[#f0f6ff]">
                  <div className="text-[20px] text-[#004166] mb-[30px] font-bold">網頁設計特選套餐</div>
                  <div className="text-[20px] text-[#004166] mb-[30px]">網頁設計套餐<br/>費用低至</div>
                  <div className="text-[60px] font-bold text-[#004166] border-b-[1px] border-[#004166] whitespace-nowrap"><span className="text-[18px] inline-block align-super">$</span>2688</div>
                </div>
                <div className="px-[50px] md:px-[30px] lg:px-[80px] py-[50px] pt-[15px]">
                <ul className="mb-[40px] list-outside list-disc pl-[15px]">
                    <li className="pb-[5px]">一至五頁專業網站版面</li>
                    <li className="pb-[5px]">電腦版 及 手機版設計</li>
                    <li className="pb-[5px]">首頁動態横額</li>
                    <li className="pb-[5px]">網頁查詢表格</li>
                    <li className="pb-[5px]">返回頁首按鍵</li>
                    <li className="pb-[5px]">網頁Icon</li>
                    <li className="pb-[5px]">網頁Favicon</li>
                    <li className="pb-[5px]">相片畫集</li>
                    <li className="pb-[5px]">動態相簿</li>
                    <li className="pb-[5px]">社交平台連結 (Facebook / Instagram / Twitter)</li>
                    <li className="">一種語言</li>
                  </ul>
                  <a href="#" className="text-[12px] block text-center bg-[#b4e1fa] py-[12px] rounded-[30px] shadow-[0_20px_20px_0_rgba(0,0,0,0.1)] hover:bg-[#0079bf] hover:text-[#fff] hover:-translate-y-[5px] transition-transform duration-300">索取報價單 </a>
                </div>
              </div>
              <div className="flex flex-col rounded-[20px] self-start bg-[#fff] shadow-[0_0_40px_0_rgba(0,65,102,0.43)] md:ml-[30px]">
                <div className="px-[50px] md:px-[30px] lg:px-[80px] py-[50px] pb-[20px] rounded-t-[20px] text-center bg-[#f0f6ff]">
                  <div className="text-[20px] text-[#004166] mb-[30px] font-bold">網頁設計特選套餐</div>
                  <div className="text-[20px] text-[#004166] mb-[30px]">網頁設計套餐<br/>費用低至</div>
                  <div className="text-[60px] font-bold text-[#004166] border-b-[1px] border-[#004166] whitespace-nowrap"><span className="text-[18px] inline-block align-super">$</span>2688</div>
                </div>
                <div className="px-[50px] md:px-[30px] lg:px-[80px] py-[50px] pt-[15px]">
                <ul className="mb-[40px] list-outside list-disc pl-[15px]">

                    <li className="pb-[5px]">動態相簿</li>
                    <li className="pb-[5px]">社交平台連結 (Facebook / Instagram / Twitter)</li>
                    <li className="">一種語言</li>
                  </ul>
                  <a href="#" className="text-[12px] block text-center bg-[#b4e1fa] py-[12px] rounded-[30px] shadow-[0_20px_20px_0_rgba(0,0,0,0.1)] hover:bg-[#0079bf] hover:text-[#fff] hover:-translate-y-[5px] transition-transform duration-300">索取報價單 </a>
                </div>
              </div>
            </div>

          </div>
        </div>

      </div>
             <ServiceForm />

    </div>
    </DocumentMeta>
  )
}
