import BlockContent from "@sanity/block-content-to-react"
import { useParams } from "react-router-dom"
import { useState, useEffect } from "react"
import { getPostDetail } from '../services';
import Categories from './Categories';
import PostWidget from './PostWidget';
import Sidebar from './Sidebar';
import Breadcrumb from './Breadcrumb';
import Ads from './Ads';
import SocialMediaShare from './SocialMediaShare';
import DocumentMeta from 'react-document-meta';


export default function BlogContent() {

  const [postDetail, setPostDetail] = useState([])
  const [postDetailMenu, setPostDetailMenu] = useState([])
  const [articleTitle, setArticleTitle] = useState([])
  const [categoryTitle, setCategoryTitle] = useState([])
  const [categoryAlias, setCategoryAlias] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  
  const { slug } = useParams()
  const meta = {
    title: postDetail.title + ' - WebTechArea',
    description: postDetail.description,
    canonical: 'https://webtecharea.com/'+categoryAlias+'/'+articleTitle,
    meta: {
      charset: 'utf-8',
      name: {
        keywords: ''
      }
    }
  };
  var menu = []
  var numberOfSubTitle = 0

  const serializers = {
    types: {
      block (props) {
        switch (props.node.style) {
          case 'p':
            return <p className="text-base text-black leading-loose"></p>
          case 'h2':
            numberOfSubTitle++ 
            var showAds = <></>
            if(numberOfSubTitle == 5 || numberOfSubTitle== 10){
              showAds = <Ads adsSlotID='4515276457' adsLayout='in-article' adsFormat='fluid'/>
            }
            return <><h2 id={props.children} className="scroll-mt-24 text-xl font-extrabold text-blue-900 my-6">{props.children}</h2>{showAds}</>
          default:
            return <p>{props.children}<br/></p>
        }
      },
    }
  }

  useEffect(() => {
    getPostDetail(slug).then((newPost) => {
      setPostDetail(newPost[0]);
      setCategoryTitle(newPost[0].categories[0].title);
      setCategoryAlias(newPost[0].categories[0].alias);
      setArticleTitle(newPost[0].title)
      const postBody = newPost[0].body
      {
        postBody.map((item, index) => (
          item.style == 'h2' ? menu[index] = item.children[0].text : ''
        ))
      }
      setPostDetailMenu(menu)
      setIsLoading(false)
    });
  }, []);

  const level1 = { name: '部落格', href: '/blog', }
  const level2 = { name: categoryTitle, href: "/blog/" + categoryAlias }

  return (
    <DocumentMeta {...meta}>
    <>
      <Sidebar menu={postDetailMenu} />
      <main className="flex-auto w-full min-w-0 lg:static lg:max-h-full lg:overflow-visible">
        <div className="block lg:flex w-full">
          <div className="flex-auto pt-6 min-w-0 max-w-5xl lg:px-8 lg:pt-10 pb:12 xl:pb-24 lg:pb-16">
            {isLoading ? (
              <h1 className="uppercase font-bold text-3xl tracking-wide mb-5 md:text-6xl lg:text-4xl flex items-center mt-16 justify-center">
                載入文章中...
              </h1>
            ) : (
              <div>
                <div className="block md:flex md:justify-between">
                <Breadcrumb level1={level1} level2={level2} />
                <SocialMediaShare alias={categoryAlias} title={articleTitle} />
                </div>
                <div className="pb-2 mb-4 border-b border-gray-200 dark:border-gray-800">
                  <h1 className="inline-block text-2xl font-extrabold tracking-tight text-gray-900 dark:text-white" id="content">{postDetail.title}</h1>
                </div>
                <div className="">
                  {postDetail.mainImage && postDetail.mainImage.asset && (
                    <img
                      src={postDetail.mainImage.asset.url}
                      alt={postDetail.title}
                      title={postDetail.title}
                      className="my-2"
                    />
                  )}
                  <BlockContent
                    blocks={postDetail.body}
                    projectId="vmh5gsdm"
                    dataset="production"
                    serializers={serializers}
                    className="break-words"
                  />
                </div>
              </div>

            )}
          </div>
          <div className="flex-none z-20 pl-0 mr-8 md:mr-2 w-full lg:w-80 xl:text-sm -mt-12 md:-mt-0">
            <div className="lg:sticky relative top-24">
              <PostWidget />
              <Categories />
            </div>
          </div>
        </div>
      </main>
    </>
    </DocumentMeta>
  )
}
