import {
    FacebookShareButton,
    FacebookIcon,
    EmailShareButton,
    EmailIcon,
    LinkedinShareButton,
    LinkedinIcon,
    LineShareButton,
    LineIcon,
    TelegramShareButton,
    TelegramIcon,
    TwitterShareButton,
    TwitterIcon,
    WhatsappShareButton,
    WhatsappIcon
  } from "react-share";

export default function SocialMediaShare(info) {
    const icons = [
        {
            button: FacebookShareButton,
            icon: FacebookIcon,
        },
        {
            button:EmailShareButton,
            icon:EmailIcon,
        },
        {
            button:LinkedinShareButton,
            icon:LinkedinIcon,
        },
        {
            button:LineShareButton,
            icon:LineIcon,
        },
        {
            button:TelegramShareButton,
            icon:TelegramIcon,
        },
        {
            button:TwitterShareButton,
            icon:TwitterIcon,
        },
        {
            button:WhatsappShareButton,
            icon:WhatsappIcon,
        },
    ]

    const shareTitle = encodeURI(info.title)
    const shareAlias = info.alias
    const shareURL = "https://www.webtecharea.com/blog/"+ shareAlias + '/' + shareTitle
    const iconSize = 32
  return (
    <div className="">
        {icons.map((item, index) => (
            <item.button key={index} url={shareURL}>
                <item.icon className="mx-1" size={iconSize} round={true} />
            </item.button>
        ))}
    </div>
  )
}
