
import BlogContent from '../components/BlogContent';

export default function BlogDetail() {
  return (
    <div className="px-4 mx-auto w-full max-w-8xl flex">
      <BlogContent />
    </div>
  )
}
