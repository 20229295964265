import React, { useState } from 'react'
import ServiceForm from "../../components/ServiceForm";
import Accordion from '../../components/Accordion';
import { seoSliderArray, seoQuestionArray } from "../../components/Data";
import DocumentMeta from 'react-document-meta';
import {
  BookmarkIcon
} from '@heroicons/react/outline'

export default function SEO() {
  const meta = {
    title: 'SEO優化|專業服務|提昇網站SEO排名 - WebTechArea',
    description: '我們提供專業的SEO服務，以幫助您的網站在搜尋引擎中更好地被搜尋和發現。我們的團隊擅長使用最佳的SEO策略，包括關鍵字研究、內容優化、技術優化、以及建立高品質的外部鏈接，以提高您網站的排名，吸引更多的訪問者和潛在客戶。',
    canonical: 'https://webtecharea.com/service/seo',
    meta: {
      charset: 'utf-8',
      name: {
        keywords: ''
      }
    }
  };
  const [slideIndex, setSlideIndex] = useState(1)

  const moveDot = index => {
    setSlideIndex(index)
  }

  return (
    <DocumentMeta {...meta}>
      <div>
        <section className="relative h-[calc(40vh-68px)] sm:h-[calc(60vh)] bg-seo bg-center bg-cover py-[80px] flex items-center before:absolute before:left-1/2 before:-translate-x-1/2 before:-translate-y-1/2 before:bottom-[-65px] before:scale-[.6] ">
        </section>
        <section className="pt-4">
          <div className="w-full px-[40px] md:px-[15px] mx-auto sm:max-w-[540px] md:max-w-[720px] lg:max-w-[1000px]">
            <div className="text-center my-[3rem]">
              <h2 className="text-[#4c4c4c] text-[2rem] font-bold leading-[2.5rem] tracking-[1px] mb-2">為什麼要做好 SEO?</h2>
              <h5 className="text-[1.1rem] tracking-[1.4px] leading-[1.5rem] mb-[.5rem]">消費者天天搜尋，品牌何不守株待兔？</h5>
            </div>
            <div className="flex flex-col md:flex-row items-center md:items-start flex-wrap mb-0 md:mb-[3rem]">
              <div className="flex items-center md:items-start md:flex-row md:w-1/2 mb-[3rem] px-[1rem]md:pr-[1rem]">
              <div className="mb-[1rem] md:mb-0 md:pr-[1rem] lg:px-[1rem] max-w-[25%] min-w-[25%]">
              <BookmarkIcon className='w-3/4'/>
                </div>
                <div className="text-center md:text-left">
                  <h4 className="font-bold text-[1.3rem] tracking-[1.3px] leading-[1.75rem] mb-[.5rem]">增加網站流量</h4>
                  <div className="">搜尋引擎是網站主要的流量來源之一。當網站在搜索引擎的排名提高時，更多的人會訪問網站，從而增加網站的流量。</div>
                </div>

              </div>
              <div className="flex items-center md:items-start md:flex-row md:w-1/2 mb-[3rem] px-[1rem]md:pr-[1rem]">
              <div className="mb-[1rem] md:mb-0 md:pr-[1rem] lg:px-[1rem] max-w-[25%] min-w-[25%]">
              <BookmarkIcon className='w-3/4'/>
                </div>
                <div className="text-center md:text-left">
                  <h4 className="font-bold text-[1.3rem] tracking-[1.3px] leading-[1.75rem] mb-[.5rem]">提高品牌知名度</h4>
                  <div className="">排名在搜索引擎前幾頁的網站通常被認為是比較可信和可靠的。當網站排名提高時，品牌知名度也會隨之提高。</div>
                </div>

              </div>
              <div className="flex items-center md:items-start md:flex-row md:w-1/2 mb-[3rem] px-[1rem]md:pr-[1rem]">
              <div className="mb-[1rem] md:mb-0 md:pr-[1rem] lg:px-[1rem] max-w-[25%] min-w-[25%]">
              <BookmarkIcon className='w-3/4'/>
                </div>
                <div className="text-center md:text-left">
                  <h4 className="font-bold text-[1.3rem] tracking-[1.3px] leading-[1.75rem] mb-[.5rem]">提高轉換率</h4>
                  <div className="">當網站的流量增加時，也會增加潛在客戶的數量，從而提高轉換率。SEO可以將網站內容和目標受眾相匹配，從而使潛在客戶更容易轉換為付費客戶。</div>
                </div>

              </div>
              <div className="flex items-center md:items-start md:flex-row md:w-1/2 mb-[3rem] px-[1rem]md:pr-[1rem]">
              <div className="mb-[1rem] md:mb-0 md:pr-[1rem] lg:px-[1rem] max-w-[25%] min-w-[25%]">
                <BookmarkIcon className='w-3/4'/>
                </div>
                <div className="text-center md:text-left">
                  <h4 className="font-bold text-[1.3rem] tracking-[1.3px] leading-[1.75rem] mb-[.5rem]">降低營銷成本</h4>
                  <div className="">相較於其他營銷方法，SEO的成本較低。如果一個網站在搜索引擎的排名很高，那麼可以減少對其他營銷方法的依賴，從而節省營銷成本。</div>
                </div>

              </div>
            </div>
          </div>

        </section>

        <section className="pt-4">
          <div className="w-full px-[15px] mx-auto sm:max-w-[540px] md:max-w-[720px] lg:max-w-[1000px]">
            <div className="text-center mt-5">
              <h2 className="text-[#4c4c4c] text-[2rem] font-bold leading-[2.5rem] tracking-[1px] mb-2">SEO 排名費用方案</h2>
              <h5 className="text-[1.1rem] text-[#4c4c4c] tracking-[1.4px] leading-[1.5rem] mb-[1.5rem]">每月收費 HKD$3,000 起，排名保證，歡迎查詢關鍵字提案報價。</h5>
            </div>
            <div className="mt-[70px] mb-[3rem] flex justify-between items-center h-[14px] bg-[#f2f2f2]">
              {seoSliderArray.map((item, index) => (
                <div key={index} className="relative cursor-pointer" onClick={() => moveDot(index + 1)}>
                  <div className="absolute -top-[32px] w-full text-center left-1/2 -translate-x-1/2 text-[#4c4c4c] text-[18px] font-bold">{item.title}</div>
                  <div className={slideIndex === index + 1 ? "w-[40px] h-[40px] shadow-[inset_0_0_0_8px_#fff] rounded-[50%] bg-[#f4b724] border-[1px] border-[#f4b724]" : "bg-[#fff] w-[40px] h-[40px] rounded-[50%] shadow-[inset_0_0_0_8px_#f2f2f2]"}>

                  </div>
                </div>

              ))}
            </div>
            <div className="mb-[1rem] min-h-[400px]">
              <div className="max-w-[90%] md:max-w-[50%] mx-auto">
                {seoSliderArray.map((item, index) => {
                  return (

                    <div key={index} className={slideIndex === index + 1 ? "block animate-[bounce1_.3s_linear_1]" : "hidden "}>
                      <div className="p-[40px] bg-[#fff] rounded-[10px] relative drop-shadow-[2px_4px_10px_rgba(0,0,0,0.2)] mt-3 mb-4 md:mb-0  before:absolute before:left-[30px] before:-bottom-[50px] before:w-[100px]">
                        <div><span className="font-bold">可選關鍵字：</span>{item.keyword}</div>
                        <div className="mb-4"><span className="font-bold">保證第一頁：</span>{item.firstPage}</div>
                        <div className="mb-4"><span className="font-bold">適合產業：</span>{item.industry}</div>
                        <div>
                          <span className="font-bold">範例：</span>
                          <ul className="pl-[20px] list-disc">
                            {item.example.map((option, itemIndex) =>
                              <li key={itemIndex}>{option}</li>
                            )}
                          </ul>
                        </div>
                      </div>
                    </div>
                  )
                })}



              </div>
            </div>

          </div>

        </section>



        <ServiceForm />




      </div>
    </DocumentMeta>
  )
}
