import PostCard from '../components/PostCard';
import { useState, useEffect } from "react"
import { getPosts } from '../services';
import Breadcrumb from '../components/Breadcrumb';
import { useParams } from 'react-router-dom';
import DocumentMeta from 'react-document-meta';

export default function Blog() {
  const [posts, setPosts] = useState([])
  const [alias, setAlias] = useState('')
  const [title, setTitle] = useState('')
  const [description, setDescription] = useState('')
  const { category } = useParams()

  useEffect(() => {
    getPosts(category).then((newPosts) => {
      setPosts(newPosts);
      setAlias(newPosts[0].categories.alias);
      setDescription(newPosts[0].categories.description);
      setTitle(newPosts[0].categories.title);
    });

  }, [category]);

  const meta = {
    title: title + '|部落格|一站式全面數碼行銷方案 - WebTechArea',
    description: description,
    canonical: 'https://webtecharea.com/blog/'+alias,
    meta: {
      charset: 'utf-8',
      name: {
        keywords: ''
      }
    }
  };

  const level1 = { name: '部落格', href: '/blog', }
  const level2 = { name: title, href: "/blog/"+ alias }

  return (
    <DocumentMeta {...meta}>
    <div className="relative z-20 dark:bg-gray-900 pt-4 max-w-7xl mx-auto px-4 sm:px-6">
      {category ? (
        <Breadcrumb level1={level1} level2={level2}/>
      ) : (
        <Breadcrumb level1={level1}/>
      )}

      <div className="flex flex-wrap -mx-4">
        {posts.map((post, index) => (
          <PostCard key={index} post={post} index={index}/>
        ))}
      </div>
    </div>
    </DocumentMeta>
  )
}
