import { useState, useEffect } from "react"
import moment from 'moment';
import { useParams } from "react-router-dom"
import { getRelatedPosts, getPostDetail } from '../services'
import Ads from './Ads';

export default function PostWidgets() {
  const [relatedPosts, setRelatedPosts] = useState([])
  const [categoryAlias, setCategoryAlias] = useState([])
  const [ArticleTitle, setArticleTitle] = useState([])

  const { slug } = useParams()
  useEffect(() => {
    getPostDetail(slug).then((newPost) => {

      setCategoryAlias(newPost[0].categories[0].alias);
      setArticleTitle(newPost[0].title)
    });
  }, []);

  useEffect(() => {
    getRelatedPosts(categoryAlias, ArticleTitle).then((related3Posts) => {
      setRelatedPosts(related3Posts)
    });
  }, [ArticleTitle])

  return (
    <>
      <div className="mb-4">
        <Ads adsSlotID='2803557773' adsLayout='' adsFormat='auto' />
      </div>
      <div className="bg-white z-20 shadow-lg rounded-lg p-8 pb-12 mb-8">
        <h3 className="text-base mb-8 font-bold border-b pb-2">相關文章</h3>
        {relatedPosts.map((post, index) => (
          post.mainImage && post.mainImage.asset && (
          <div key={index} className="flex items-center w-full mb-4">
            <div className="w-16 flex-none">
              <a key={post.slug} href={`/blog/${post.categories.alias}/${post.title}`} ><img src={post.mainImage.asset.url} alt={post.title} className="align-middle rounded-full" /></a>
            </div>
            <div className="flex-grow ml-4">
              <p className="text-gray-500 font-xs">{moment(post.createdAt).format('MMM DD, YYYY')}</p>
              <a key={post.slug} href={`/blog/${post.categories.alias}/${post.title}`} className="text-md">{post.title}</a>
            </div>
          </div>
          )
        ))}
      </div>
    </>

  )
}
