import { getLatestPosts } from "../services";
import { useState, useEffect } from "react"
import moment from 'moment';

export default function FeaturedPosts() {
   const [recentPosts, setrecentPosts] = useState([])

   useEffect(() => {
      getLatestPosts(6, true).then((latestPosts) => {
        setrecentPosts(latestPosts)
      });
    }, [])

  return (
<section className="pb-4 sm:pb-16">
   <div className="container m-auto">
      <div className="flex flex-wrap justify-center -mx-4">
         <div className="w-full px-4">
         <div className="text-center mx-auto mb-2 max-w-[510px]">
               <h2 className="font-bold text-2xl sm:text-3xl text-dark mb-4">
                  Latest Articles
               </h2>
               <span className="font-semibold text-lg text-primary mb-4 block">
                  最新文章
               </span>
            </div>
         </div>
      </div>
      <div className="flex flex-wrap -mx-4">
         {recentPosts.map((post) => (
            <div key={post.title} className="w-full md:w-1/2 lg:w-1/3 px-4">
               <div className="max-w-[370px] mx-auto mb-10">
                  {post.mainImage && post.mainImage.asset && (
                     <a href={`/blog/${post.categories.alias}/${post.title}`}>
                    <img
                      src={post.mainImage.asset.url}
                      alt={post.title}
                      title={post.title}
                     className="block rounded overflow-hidden mb-8 h-full h-60"
                      />
                     </a>
                  )}
               <div>
                  <span
                     className="
                     bg-blue-300
                     rounded
                     inline-block
                     text-center
                     py-1
                     px-4
                     text-xs
                     leading-loose
                     font-semibold
                     text-white
                     mb-5
                     "
                     >
                  {moment(post.publishedAt).format('MMM DD, YYYY')}
                  </span>
                  <h3>
                     <a
                        href={`/blog/${post.categories.alias}/${post.title}`}
                        className="
                        font-bold
                        text-base
                        inline-block
                        text-dark
                        "
                        >
                     {post.title}
                     </a>
                  </h3>
               </div>
            </div>
         </div>
         ))}




      </div>
   </div>
</section>
);

}
