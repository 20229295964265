import React, { useState } from 'react'
import './Slider.css'
/* import BtnSlider from './BtnSlider' */
import { v4 as uuidv4 } from "uuid";
import leftArrow from "./icons/left-arrow.svg";
import rightArrow from "./icons/right-arrow.svg";
/* import dataSlider from './dataSlider' */

export default function Slider() {
    const dataSlider = [
        {
            id: uuidv4(),
            title: "WebTechArea 網頁製作",
            subTitle: "讓我們幫您創造專屬於您的網路世界",
            alias: "web-design"
        },
        {
            id: uuidv4(),
            title: "WebTechArea SEO優化",
            subTitle: "",
            alias: "seo"
        },
        {
            id: uuidv4(),
            title: "",
            subTitle: "",
            alias: "digital-marketing"
        },

    ];


    const [slideIndex, setSlideIndex] = useState(1)
    const [slideLanding, setSlideLanding] = useState('web-design')
   
    const nextSlide = () => {
        if (slideIndex !== dataSlider.length) {
            setSlideIndex(slideIndex + 1)
            setSlideLanding(dataSlider[slideIndex].alias)
        }
        else if (slideIndex === dataSlider.length) {
            setSlideIndex(1)
            setSlideLanding(dataSlider[0].alias)
        }
    }

    const prevSlide = () => {
        if (slideIndex !== 1) {

            setSlideIndex(slideIndex - 1)
            setSlideLanding(dataSlider[slideIndex - 2].alias)

        }
        else if (slideIndex === 1) {
            setSlideIndex(dataSlider.length)
            setSlideLanding(dataSlider[slideIndex + 1].alias)

        }

    }

    const moveDot = index => {
        setSlideIndex(index)
    }

    return (
        <div className="container-slider">
            {dataSlider.map((obj, index) => {
                return (
                    <div
                        key={obj.id}
                        className={slideIndex === index + 1 ? "slide active-anim" : "slide"}
                    >
                        <a href={"https://www.webtecharea.com/service/" + slideLanding}>
                        <img
                            src={require(`../../images/${obj.alias}.jpg`)}
                        />
                        </a>
                    </div>
                )
            })}
            <button
                onClick={nextSlide}
                className="btn-slide next"
            >
                <img src={rightArrow} />
            </button>
            <button
                onClick={prevSlide}
                className="btn-slide prev"
            >
                <img src={leftArrow} />
            </button>
            {/* <BtnSlider moveSlide={nextSlide} direction={"next"} />
            <BtnSlider moveSlide={prevSlide} direction={"prev"}/> */}

            <div className="container-dots">
                {dataSlider.map((item, index) => (
                    <div
                        key={index}
                        onClick={() => moveDot(index + 1)}
                        className={slideIndex === index + 1 ? "dot active" : "dot"}
                    >

                    </div>
                ))}
            </div>
            {dataSlider.map((obj, index) => {
                return (
                    <div
                        key={obj.id}
                        className={slideIndex === index + 1 ? "txt-container active" : "txt-container"}
                    >
                        <h1 className='text-3xl sm:text-4xl mb-10 sm:mb-6'>{obj.title}</h1>
                        <h2 className='text-white text-xl sm:text-3xl'>{obj.subTitle}</h2>


                    </div>
                )
            })}
        </div>
    )
}
