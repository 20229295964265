import moment from 'moment';
import Ads from './Ads';

export default function PostCard(post) {
   var index = post.index
   post = post.post
   return (
      <>   {(index % 5 == 0 && index != 0) ?
         <div className="w-full md:w-1/2 lg:w-1/3 px-4">
            <div className="max-w-[370px] mx-auto mb-10">
               <Ads adsSlotID='6227797464' adsLayout='' adsFormat='auto' />

            </div></div>
         : <></>}
         <div className="w-full md:w-1/2 lg:w-1/3 px-4">
            <div className="max-w-[370px] mx-auto mb-10">
               {post.mainImage && post.mainImage.asset && (
                  <a href={`/blog/${post.categories.alias}/${post.title}`} className="block rounded overflow-hidden mb-8 h-full md:h-60">
                     <img
                        src={post.mainImage.asset.url} alt={post.title}
                        className="w-full h-full object-cover"
                     />
                  </a>
               )}
               <div>
                  <span
                     className="
                     bg-blue-500
                     rounded
                     inline-block
                     text-center
                     py-1
                     px-4
                     text-xs
                     leading-loose
                     font-semibold
                     text-white
                     mb-5
                     "
                  >
                     {moment(post.publishedAt).format('MMM DD, YYYY')}
                  </span>
                  <h2>
                     <a
                        href={`/blog/${post.categories.alias}/${post.title}`}
                        className="
                        font-semibold
                        text-lg
                        mb-4
                        inline-block
                        text-dark
                        hover:text-primary
                        "
                     >
                        {post.title}
                     </a>
                  </h2>

               </div>
            </div>
         </div>




      </>













   )
}
