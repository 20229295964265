import client from "../client"


export const getPostDetail = async (slug) => {
  const result =  client
  .fetch(
    `*[slug.current == "${slug}"] {
      title,
      description,
      body,
      categories[] -> {
        title,
        alias
      },
      mainImage {
        asset -> {
          _id,
          url
        },
        alt
      }
    }`
  )
  return result;
};

export const getPosts = async (catAlias) => {
  const filterCondition = (catAlias) ? `&& *[_type == "category" && alias == "${catAlias}"][0]._id in categories[]._ref` : ''
  const result =  client
  .fetch(
    `
    *[_type=='post' ${filterCondition} ] | order(publishedAt desc){
      title,
      slug,
      categories[0]->{alias, title, description},
      mainImage {
        asset -> {
          _id,
          url
        },
        alt
      },
      publishedAt
    }
    `
  )
  return result;
};


export const getCategories = async () => {
const result =  client
    .fetch(
    `*[_type == "category"] {
        title,
        slug,
        alias
    }`
    )
return result;
};


export const getLatestPosts = async (limit, isImageNeeded = false) => {
  const filterCondition = (isImageNeeded) ? "mainImage {asset -> { _id, url }, alt},publishedAt," : ""
  const result =  client
      .fetch(
        `*[_type == "post"] | order(publishedAt desc) {title, ${filterCondition} categories[0]->{alias, title}} [0...${limit}]`
      )
  return result;
  };

  export const getRelatedPosts = async (alias, title) => {
    const randomCondition = Math.floor(Math.random() * 2);
    const randomOrder = Math.floor(Math.random() * 2);
    const condition = (randomCondition === 0) ? 'title' : 'publishedAt'
    const order = (randomOrder === 0) ? 'asc' : 'desc'
    const result =  client
        .fetch(
          `
          *[_type=='post' && title != "${title}" && *[_type == "category" && alias == "${alias}"][0]._id in categories[]._ref] | order(${condition} ${order}){
            title,
            slug,
            categories[0]->{alias, title},
            mainImage {
              asset -> {
                _id,
                url
              },
              alt
            },
            publishedAt
          }[0..2]`
        )
    return result;
    };
