export default function Sidebar(menu) {
  const menus = menu.menu
  return (
    <aside id="sidebar" className="hidden fixed inset-0 z-20 flex-none h-full lg:static lg:h-auto lg:overflow-y-visible lg:pt-0 lg:w-60 lg:block">
      <div id="navWrapper" className="overflow-hidden overflow-y-auto z-20 h-full bg-white scrolling-touch max-w-2xs lg:h-screen lg:block lg:sticky top-24 dark:bg-gray-900 lg:mr-0">
        <nav id="nav" className="pt-16 px-1 pl-3 lg:pl-0 lg:pt-2 overflow-y-auto font-medium text-base lg:text-sm pb-10 lg:pb-20 sticky?lg:h-(screen-18)" aria-label="Docs navigation">
          <h5 className="mt-8 pb-2 text-base font-bold tracking-wide text-gray-900 uppercase border-b border-gray-200">目錄</h5>
          <ul className="py-1 list-none fw-normal small">
            {menus.map((item, index) => (
              <li key={index} >
                <a href={`#${item}`} className="py-2 transition-colors duration-200 relative block hover:text-gray-900 text-gray-500">{item} </a>
              </li>
            ))}
          </ul>
        </nav>
      </div>
    </aside>
  )
}
