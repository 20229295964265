import Form from "../components/Form";
import { commonContact } from "../components/Data";
import Breadcrumb from "../components/Breadcrumb";
import DocumentMeta from 'react-document-meta';

export default function ContactUs() {
   const meta = {
      title: '聯絡我們 - WebTechArea',
      description: '聯絡我們 - WebTechArea',
      canonical: 'https://webtecharea.com/contact-us',
      meta: {
         charset: 'utf-8',
         name: {
            keywords: ''
         }
      }
   };

   const level1 = { name: '聯絡我們', href: '/contact-us', }
   return (
      <DocumentMeta {...meta}>
         <section className="bg-white pb-4 overflow-hidden relative z-8">
            <div className="relative z-20 dark:bg-gray-900 pt-4 max-w-7xl mx-auto px-4 sm:px-6">
               <Breadcrumb level1={level1} />

               <div className="flex flex-wrap lg:justify-between -mx-4">
                  <div className="w-full lg:w-1/2 xl:w-6/12 px-4">
                     <div className="max-w-[570px] mb-12 lg:mb-0">
                        <h1
                           className="
                  mb-6
                  uppercase
                  font-bold
                  text-[32px]
                  "
                        >
                           聯絡我們
                        </h1>
                        <p className="text-base text-body-color leading-relaxed mb-9">
                           我們提供一站式網頁設計服務，包括：<a className="text-blue-700" href="/service/web-design">網頁製作</a>、<a className="text-blue-700" href="/service/seo">SEO優化</a>及<a className="text-blue-700" href="/service/digital-marketing">數碼營銷</a>等。    填寫表格後，我們將主動與您聯繫，了解您的需求及介紹我們的服務，並提供免費咨詢以討論計劃內容及細節。
                        </p>

                        {commonContact.map((item) => (
                           <div key={item.name} className="flex mb-8 max-w-[370px] w-full">
                              <div className="
                     max-w-[60px]
                     sm:max-w-[70px]
                     w-full
                     h-[60px]
                     sm:h-[70px]
                     flex
                     items-center
                     justify-center
                     mr-6
                     overflow-hidden
                     bg-[#3056d3] bg-opacity-5
                     text-[#3056d3]
                     rounded
                     " >
                                 <item.icon className="flex-shrink-0 h-6 w-6 text-gray-400" aria-hidden="true" />
                              </div>
                              <div className="w-full">
                                 <a href={item.href}>
                                    <p className="text-base text-body-color">
                                       {item.name}
                                    </p>
                                 </a>
                              </div>
                           </div>
                        ))}
                     </div>
                  </div>
                  <Form />
               </div>
            </div>
         </section>
      </DocumentMeta>
   )
}
