import Form from "./Form";
import formImage from '../images/form.jpg'

export default function ServiceForm() {
  return (
    <section className="relative pt-12 before:absolute before:left-1/2 before:-translate-x-1/2 before:bottom-[-65px] before:scale-[.6]">
    <div className="w-full px-[15px] mx-auto sm:max-w-[540px] md:max-w-[720px] lg:max-w-[1000px]">
      <div className="flex justify-between items-center">
        <div className="hidden md:block w-full lg:w-1/2 px-4 bg-[#fff]">
          <img src={formImage} alt="contact_us_img" />
        </div>
        <Form />
      </div>

    </div>
    </section>
  )
}
