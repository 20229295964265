import Slider from '../../components/Carousel/Slider';
import ServiceForm from "../../components/ServiceForm";
import DocumentMeta from 'react-document-meta';
import digitalMarketingPlan from '../../images/digital-marketing-plan.jpg'
import digitalMarketingService from '../../images/digital-marketing-service.jpg'
import digitalMarketingNetwork from '../../images/digital-marketing-network.jpg'
import digitalMarketingNote from '../../images/digital-marketing-note.jpg'
import {
  ChatIcon,
} from '@heroicons/react/outline'

export default function DigitalMarketing() {
  const meta = {
    title: '數碼營銷|專業服務|一站式全面數碼行銷方案 - WebTechArea',
    description: '我們提供全方位的數碼營銷服務，包括社交媒體、電子郵件、內容營銷、以及其他網絡營銷策略，以幫助您的業務擴大規模、提高知名度和銷售額。我們的團隊專注於了解您的目標客戶，並創造定制化的營銷策略，以達到最大的效果。',
    canonical: 'https://webtecharea.com/service/digital-marketing',
    meta: {
      charset: 'utf-8',
      name: {
        keywords: ''
      }
    }
  };
  return (
    <DocumentMeta {...meta}>
      <div>
        <section className="py-[60px] bg-center	 bg-digital-marketing bg-cover h-[calc(50vh)] "></section>
        <section className="py-[60px] px-[30px]">
          <div className="max-w-[1200px] mx-auto">
            <div className="flex flex-col justify-center md:flex-row">
              <div className="mb-[30px] md:mb-0">
                <div className="mb-[20px] text-center md:text-left">
                  <h3 className="text-[24px] text-[rgba(221,0,0,1)] font-bold">HK$750/月起</h3>
                  <h2 className="text-[36px] font-bold">WebTechArea廣告計劃</h2>
                </div>
                <div className="rounded-[6px] shadow-[0_0_10px_0_rgba(0,0,0,.2)] p-[30px] bg-[transparent] flex items-center flex-col-reverse lg:flex-row">
                  <div className="">
                    <div className="text-[20px] text-[rgba(45,24,110,1)] font-bold mb-[20px]">入門計劃</div>
                    <ul className="list-disc pl-[20px]">
                      <li>確定廣告目標</li>
                      <li>選擇關鍵字</li>
                      <li>創建廣告素材</li>
                      <li>設置追蹤程式</li>
                      <li>監測廣告效果</li>
                      <li>定期檢查廣告效果</li>
                    </ul>
                  </div>
                  <div className="lg:ml-[30px]">
                    <img className="max-w-[300px] lg:max-w-[200px] xl:max-w-[300px] mb-[30px] lg:mb-0" src={digitalMarketingPlan} alt="thumbnail" />
                  </div>
                </div>
              </div>
              <div className="md:ml-[30px]">

                <div className="rounded-[6px] shadow-[0_0_10px_0_rgba(0,0,0,.2)] p-[30px] bg-[transparent] flex items-center flex-col-reverse lg:flex-row">
                  <div className="">
                    <div className="text-[20px] text-[rgba(45,24,110,1)] font-bold mb-[20px]">進階計劃</div>
                    <ul className="list-disc pl-[20px]">
                      <li>定義更精確的目標受眾</li>
                      <li>使用關鍵字分析工具</li>
                      <li>使用A/B測試</li>
                      <li>創建不同形式的廣告</li>
                      <li>定期檢查廣告效果</li>
                    </ul>
                  </div>
                  <div className="lg:ml-[30px]">
                    <img className="max-w-[300px] lg:max-w-[200px] xl:max-w-[300px] mb-[30px] lg:mb-0" src={digitalMarketingPlan} alt="thumbnail" />
                  </div>
                </div>
                <div className="mt-[50px] text-center md:text-right">
                  <a className="text-[16px] bg-[#000] rounded-[50px] px-[20px] py-[12px] inline-block transition-[transform] hover:scale-110 group" href="#">
                    <div className="flex flex-nowrap ">
                      <div className="mr-[10px]">
                        <ChatIcon className="text-blue-300 w-6" />
                      </div>
                      <div className="text-[16px] text-[#fff] group-hover:text-[#fff]">立即聯絡</div>

                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>

        </section>
        <section className="py-[60px] px-[30px] bg-blue-500">
          <div className="max-w-[1200px] mx-auto">
            <div className="mb-[40px] text-center md:text-left">
              <h2 className="text-[36px] font-bold text-[#fff] mb-[20px]">服務特色</h2>
            </div>
            <div className="flex flex-wrap md:-mx-[20px] justify-center md:justify-start">
              <div className="rounded-[10px] md:w-[43%] flex flex-col lg:flex-row bg-[#fff] p-[20px] items-center mx-0 md:mx-[20px] m-[20px]">
                <div>
                  <div className="text-[18px] text-[#2d186e] font-bold">廣告策略設計</div>
                  <hr className="w-[28%] my-[8px] border-[#2d186e]" />
                  <div className="text-[14px] leading-[1.5]">根據客戶需求，制定廣告的目標受眾、關鍵字、廣告內容和投放位置等策略</div>
                </div>
                <div>
                  <img className="md:max-w-[300px]" src={digitalMarketingService} alt="thumbnail" />
                </div>

              </div>

              <div className="rounded-[10px] md:w-[43%] flex flex-col lg:flex-row bg-[#fff] p-[20px] items-center m-[20px] mx-0 md:mx-[20px]">
                <div>
                  <div className="text-[18px] text-[#2d186e] font-bold">廣告創意製作</div>
                  <hr className="w-[28%] my-[8px] border-[#2d186e]" />
                  <div className="text-[14px] leading-[1.5]">創建廣告文案、圖片/視頻素材，編輯廣告視頻等</div>
                </div>
                <div>
                  <img className="md:max-w-[300px]" src={digitalMarketingService} alt="thumbnail" />
                </div>

              </div>

              <div className="rounded-[10px] md:w-[43%] flex flex-col lg:flex-row bg-[#fff] p-[20px] items-center m-[20px] mx-0 md:mx-[20px]">
                <div>
                  <div className="text-[18px] text-[#2d186e] font-bold">平台設置</div>
                  <hr className="w-[28%] my-[8px] border-[#2d186e]" />
                  <div className="text-[14px] leading-[1.5]">在Google Ads平台上創建廣告帳戶、設置廣告組、關鍵字和廣告內容等</div>
                </div>
                <div>
                  <img className="md:max-w-[300px]" src={digitalMarketingService} alt="thumbnail" />
                </div>

              </div>
              <div className="rounded-[10px] md:w-[43%] flex flex-col lg:flex-row bg-[#fff] p-[20px] items-center m-[20px] mx-0 md:mx-[20px]">
                <div>
                  <div className="text-[18px] text-[#2d186e] font-bold">定期報告</div>
                  <hr className="w-[28%] my-[8px] border-[#2d186e]" />
                  <div className="text-[14px] leading-[1.5]">每月或每季度向客戶提交廣告效果報告，包括廣告花費、轉換率、點擊率等指標的分析和評估</div>
                </div>
                <div>
                  <img className="md:max-w-[300px]" src={digitalMarketingService} alt="thumbnail" />
                </div>

              </div>
            </div>
            <div className="mt-[50px] text-center md:text-right">
              <a className="text-[16px] bg-[#fff] rounded-[50px] px-[20px] py-[12px] inline-block transition-[transform] hover:scale-110 group" href="#">
                <div className="flex flex-nowrap ">
                  <div className="mr-[10px]">
                    <ChatIcon className="text-blue-300 w-6" />
                  </div>
                  <div className="text-[16px] text-[#000] group-hover:text-[#000]">立即聯絡</div>

                </div>
              </a>
            </div>

          </div>
        </section>
        <section className="py-[60px] px-[30px]">
          <div className="max-w-[1200px] mx-auto">
            <div className="text-center px-[30px] mb-[50px]">
              <h1 className="font-bold text-[36px] mb-[30px]">廣告效果</h1>
              <p className="text-[18px]">有針對性對行業特性，市場規模，選擇google關鍵字，制定個性化網路行銷方案及著陸頁</p>
            </div>
            <div className="flex flex-col md:flex-row">
              <div className="md:w-1/2">
                <div className="md:max-w-[400px] md:pl-[20px] mx-auto">
                  <img src={digitalMarketingNetwork} alt="" />
                </div>
                <hr className="w-full my-[20px] border-[3px] border-[#2d186e]" />
              </div>
              <div className="md:ml-[50px] md:w-1/2">
                <hr className="w-full my-[20px] border-[3px] border-[#2d186e] hidden md:block" />
                <div className="pt-[20px] md:pr-[20px]">
                  <div className="flex mb-[30px] items-center">
                    <div className="max-w-[100px]">
                      <img src={digitalMarketingNote} alt="thumbnail" />
                    </div>
                    <div className="text-[18px] text-[rgba(110,67,24,1)] leading-[1.5] pl-[20px]">平均點擊成本（CPC）：顯示每次點擊的平均花費</div>
                  </div>
                  <div className="flex mb-[30px] items-center">
                    <div className="max-w-[100px]">
                      <img src={digitalMarketingNote} alt="thumbnail" />
                    </div>
                    <div className="text-[18px] text-[rgba(110,67,24,1)] leading-[1.5] pl-[20px]">平均點擊率（CTR）：顯示廣告獲得的平均點擊率，即廣告展示次數和點擊次數的比率</div>
                  </div>
                  <div className="flex items-center">
                    <div className="max-w-[100px]">
                      <img src={digitalMarketingNote} alt="thumbnail" />
                    </div>
                    <div className="text-[18px] text-[rgba(110,67,24,1)] leading-[1.5] pl-[20px]">目標受眾：顯示廣告吸引的目標受眾的年齡、性別、地理位置等信息</div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </section>

        <section className="p-4 bg-[rgba(255,205,155,0.04)]">
          <div className="max-w-[1200px] mx-auto">
            <div className="text-center px-[30px] mb-[50px]">
              <h1 className="font-bold text-[36px] mb-[30px]">售後優化：精細化管理您的SEM廣告</h1>
              <p className="text-[18px]">度身訂製您的SEM廣告目標計劃，以目標為導向，精細化運營你的SEM廣告</p>
            </div>
            <div>
              <Slider />
            </div>

          </div>
        </section>
        <section className="">

          {/*<div>
              <Swiper arrayList={DigitalMarketingSwiperList} />
          </div>*/}

        </section>
        
        <ServiceForm />
            
      </div>
    </DocumentMeta>
  )
}
