import { commonServices } from "./Data";
export default function FeaturedServices() {

  return (
<section className="py-16">
   <div className="container m-auto">
      <div className="flex flex-wrap -mx-4">
         <div className="w-full px-4">
            <div className="text-center mx-auto mb-2 max-w-[510px]">
               <h2 className="font-bold text-2xl sm:text-3xl text-dark mb-4">
                  Our Services
               </h2>
               <span className="font-semibold text-lg text-primary block">
                  服務項目
               </span>
            </div>
         </div>
      </div>
      <div className="flex flex-wrap -mx-4">
         {commonServices.map((item, index) => (
            <div key={index} className="w-full md:w-1/2 lg:w-1/3 px-4">
            <a href={`service/${item.alias}`}>
            <div
               className="
               p-10
               md:px-7
               xl:px-10
               rounded-[20px]
               bg-white
               shadow-md
               hover:shadow-lg
               mb-8
               "
               >
               <div className='flex'>
                  <item.icon className="flex-shrink-0 h-8 w-8 text-indigo-600" aria-hidden="true" />
                  <h4 className="font-semibold text-xl text-dark mb-3 pl-2">
                     {item.name}
                  </h4>
               </div>
               <p className="mt-2 leading-7 text-body-color">
                  {item.description}
               </p>
            </div>
            </a>
         </div>
         ))}

      </div>
   </div>
</section>
);

}
